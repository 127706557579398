import React from "react"
import Layout from "../../components/layoutEn"
import SEO from "../../components/layout/seo"

import MainImg from "../../img/whereEL.jpg"

function Elevator() {
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div className="fobWrap elevWrap">
        <p className="fobMainImg escalMainImg">
          <img src={MainImg} alt="" />
        </p>
        <div className="sectionWrap fobSectionWrap elevOverview">
          <div className="textWrap">
            <h2>Elevator</h2>
            <p className="bodyText">
              The maintenance status of the elevator industry is very dangerous.
              It is very difficult for a small number of maintenance personnel
              to handle many tasks, such as self-inspection, regular inspection,
              and self-inspection according to legal regulations, as well as
              emergency call service at the request of users. However, it is
              difficult to increase the number of engineers, so it is difficult
              to operate the company. <br />
              <br />
              Therefore, the government has devised a policy so that the
              inspection cycle for the site to which the latest remote
              monitoring technology is applied is somewhat relaxed and
              intensively maintained. <br />
              The ELSA Platform not only supports these parts, but also provides
              various functions necessary for elevator maintenance. Beyond unit
              elevator management, manage materials and scheduling for all
              elevators organically and increase work efficiency.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Elevator
